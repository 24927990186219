import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get_web_configuration_api } from "../../apis/apis"; // Import the correct API function

// Async thunk to fetch web configuration
export const fetchWebConfig = createAsyncThunk(
  "webConfig/fetchWebConfig",
  async (data) => {
    const response = await get_web_configuration_api(data);
    return response.data; // Extract only the `resp_data` object
  }
);

const webConfigSlice = createSlice({
  name: "webConfig",
  initialState: {
    status: null,
    componentsVisibility: {},
    // web_config: null,
    web_config: {},
    loading: false,
    error: null,
  },
  reducers: {
    // set_web_config(state, action) {
    //   state.web_config = action.payload;
    // },
    set_web_config(state, action) {
      state.web_config = {
        ...state.web_config,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWebConfig.fulfilled, (state, action) => {
        state.status = action.payload.status; // Store the status from the response
        state.componentsVisibility = action.payload.resp_data; // Store visibility config separately
        state.loading = false;
      })
      .addCase(fetchWebConfig.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});
export const { set_web_config } = webConfigSlice.actions;
export default webConfigSlice.reducer;
