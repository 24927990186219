import axios from "axios";
import CryptoJS from "crypto-js";
// export const BASE_URL = "http://35.159.9.64:5500/v1/";
export const BASE_URL = "http://schoolmantra.in:5500/v1/";

// "http://192.168.29.46:5500/v1/"

// const BASE_URL = "http://192.168.29.46:5500/v1/";

export const getDBName = () => {
  return localStorage.getItem("db_name");
};

export const apiPost = (endpoint, data) => {
  const db_name = getDBName();
  const getQueryStringValueFromHash = (key) => {
    const hash = window.location.hash;
    const queryString = hash.substring(hash.indexOf("?"));
    return new URLSearchParams(queryString).get(key);
  };
  // Check for the specific query string parameter
  const dbName = getQueryStringValueFromHash("key");
  if (db_name || dbName) {
    data.session_data = data.session_data || {};
    data.session_data.db_name = encryptData(db_name) || encryptData(dbName);
  }
  return axios.post(BASE_URL + endpoint, data);
};

// export const apiPost = (endpoint, data) => {
//   // Get the hostname from the current URL, e.g. "web.schoolmantra.in"
//   const hostname = window.location.hostname;

//   // Ensure session_data exists and set db_name to the hostname
//   data.session_data = data.session_data || {};
//   data.session_data.db_name = hostname;

//   // Make the POST request using axios
//   return axios.post(BASE_URL + endpoint, data);
// };
const secretKey = "schoolmantra";

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};
