import React, { useRef } from "react";
import Layout from "./Layout";
import NoteContext from "./context/NoteContext";
const App = () => {
  const containerRef = useRef(null);
  const ref_Home = useRef(null);
  const ref_Class = useRef(null);
  const ref_Down = useRef(null);
  const ref_Loc = useRef(null);
  const ref_School = useRef(null);
  const ref_Event = useRef(null);
  const ref_Parents = useRef(null);
  const ref_Socials = useRef(null);
  const ref_Facility = useRef(null);
  const ref_Staff = useRef(null);
  const ref_News = useRef(null);
  const ref_Events = useRef(null);
  const ref_Gallery = useRef(null);
  const ref_Birthday = useRef(null);
  // const scrollToRefWithOffset = (ref) => {
  //   if (ref?.current) {
  //     const header = document.querySelector(".header"); // ensure your header has this class
  //     const headerHeight = header ? header.offsetHeight - 21 : 0;
  //     const currentScrollY = window.scrollY;
  //     const targetTop =
  //       ref.current.getBoundingClientRect().top + currentScrollY;
  //     // If the target is below the current scroll (scrolling down), subtract header height.
  //     const offsetPosition =
  //       currentScrollY < targetTop ? targetTop - headerHeight : targetTop;

  //     window.scrollTo({
  //       top: offsetPosition,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  const scrollHome = () => {
    // if (ref_Home.current) {
    //   ref_Home.current.scrollIntoView({ behavior: "smooth" });
    // }
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
    // document.getElementById('nav_comp').scrollIntoView({behavior:'smooth'})
  };
  const scrollClasses = () => {
    if (ref_Class.current) {
      ref_Class.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollFacility = () => {
    if (ref_Facility.current) {
      ref_Facility.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollStaff = () => {
    if (ref_Staff.current) {
      ref_Staff.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollDownload = () => {
    if (ref_Down.current) {
      ref_Down.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollSchool = () => {
    if (ref_School.current) {
      ref_School.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollParents = () => {
    if (ref_Parents.current) {
      ref_Parents.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollSocials = () => {
    if (ref_Socials.current) {
      ref_Socials.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollEvent = () => {
    if (ref_Event.current) {
      ref_Event.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollContact = () => {
    if (ref_Loc.current) {
      ref_Loc.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollLatest_news = () => {
    if (ref_News.current) {
      ref_News.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollUpcoming_event = () => {
    if (ref_Events.current) {
      ref_Events.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollPhotoGallery = () => {
    if (ref_Gallery.current) {
      ref_Gallery.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollBirthday = () => {
    if (ref_Birthday.current) {
      ref_Birthday.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // const scrollHome = () => scrollToRefWithOffset(ref_Home);
  // const scrollClasses = () => scrollToRefWithOffset(ref_Class);
  // const scrollFacility = () => scrollToRefWithOffset(ref_Facility);
  // const scrollStaff = () => scrollToRefWithOffset(ref_Staff);
  // const scrollDownload = () => scrollToRefWithOffset(ref_Down);
  // const scrollSchool = () => scrollToRefWithOffset(ref_School);
  // const scrollParents = () => scrollToRefWithOffset(ref_Parents);
  // const scrollSocials = () => scrollToRefWithOffset(ref_Socials);
  // const scrollEvent = () => scrollToRefWithOffset(ref_Event);
  // const scrollContact = () => scrollToRefWithOffset(ref_Loc);
  // const scrollLatest_news = () => scrollToRefWithOffset(ref_News);
  // const scrollUpcoming_event = () => scrollToRefWithOffset(ref_Events);
  // const scrollPhotoGallery = () => scrollToRefWithOffset(ref_Gallery);
  // const scrollBirthday = () => scrollToRefWithOffset(ref_Birthday);

  return (
    <NoteContext.Provider
      value={{
        containerRef,
        ref_Home,
        ref_Class,
        ref_Down,
        ref_School,
        ref_Event,
        ref_Loc,
        ref_Parents,
        ref_Socials,
        ref_Facility,
        ref_Staff,
        ref_News,
        ref_Gallery,
        ref_Birthday,
        ref_Events,
        scrollHome,
        scrollParents,
        scrollClasses,
        scrollDownload,
        scrollContact,
        scrollSchool,
        scrollEvent,
        scrollSocials,
        scrollFacility,
        scrollStaff,
        scrollLatest_news,
        scrollUpcoming_event,
        scrollPhotoGallery,
        scrollBirthday,
      }}
    >
      <Layout />
    </NoteContext.Provider>
  );
};

export default App;
