import React, { Suspense, lazy } from "react";
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { SkeletonTheme } from "react-loading-skeleton";

const Components = lazy(() => import("./components"));
const ExploreNewsImg = lazy(() =>
  import("./components/latest_news/ExploreNewsImg")
);
const AcademicDetails = lazy(() =>
  import("./components/academics/AcademicDetails")
);
const GallerySect = lazy(() => import("./components/gallery/GallerySect"));
const EventsDetails = lazy(() =>
  import("./components/upcoming_events/EventsDetails")
);
const StaffViewAll = lazy(() =>
  import("./components/schoolstaff/StaffViewAll")
);
const ParentAllCard = lazy(() => import("./components/parents/ParentAllCard"));
const Welcome = lazy(() => import("./components/welcome"));
const Parents = lazy(() => import("./components/parents/Parents"));
const SocialFrames = lazy(() => import("./components/social_frames"));
const Academics = lazy(() => import("./components/academics"));
const Facilities = lazy(() => import("./components/facilities"));
const SchoolStaff = lazy(() => import("./components/schoolstaff"));
const Latest_News = lazy(() => import("./components/latest_news"));
const UpcomingEvents = lazy(() => import("./components/upcoming_events"));
const Gallery = lazy(() => import("./components/gallery"));
const Birthday = lazy(() => import("./components/birthday"));
const AccessDownload = lazy(() => import("./components/access_download"));
const GoogleMap = lazy(() => import("./components/googlemap"));

const Layout = () => {
  const routes = [
    { path: "/home", element: <Components /> },
    { path: "/explore_img", element: <ExploreNewsImg /> },
    { path: "/acad_details", element: <AcademicDetails /> },
    { path: "/gallery_card", element: <GallerySect /> },
    { path: "/events_detail", element: <EventsDetails /> },
    { path: "/staff_all", element: <StaffViewAll /> },
    { path: "/parent_all", element: <ParentAllCard /> },
    { path: "/welcome", element: <Welcome /> },
    { path: "/parents", element: <Parents /> },
    { path: "/media", element: <SocialFrames /> },
    { path: "/academic", element: <Academics /> },
    { path: "/facility", element: <Facilities /> },
    { path: "/staff", element: <SchoolStaff /> },
    { path: "/latest_news", element: <Latest_News /> },
    { path: "/events", element: <UpcomingEvents /> },
    { path: "/gallery", element: <Gallery /> },
    { path: "/birthday", element: <Birthday /> },
    { path: "/access_down", element: <AccessDownload /> },
    { path: "/contact_us", element: <GoogleMap /> },
  ];

  return (
    <SkeletonTheme baseColor="#EBE5E5" highlightColor="#F2F2F2">
      <HashRouter>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
      </HashRouter>
    </SkeletonTheme>
  );
};

export default Layout;
