import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  get_school_info_api,
  get_gallery_facilities_api,
  get_academics_news_api,
  get_events_staff_api,
  get_birthday_feedback_api,
  get_staff_feedback_api,
  get_birthday_location_api,
} from "../../apis/apis";
import { useSelector } from "react-redux";

// Async thunk to fetch school data
export const fetchSchoolInfo = createAsyncThunk(
  "school/fetchSchoolInfo",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const store_data = state.webConfig.web_config;
    const response = await get_school_info_api({ contact: true, store_data });
    console.log("responseDATA", response.data);
    return response.data;
  }
);

export const fetchGalleryFacilities = createAsyncThunk(
  "school/fetchGalleryFacilities",
  async (data) => {
    const response = await get_gallery_facilities_api(data);
    return response.data;
  }
);
export const fetchStaffFeedback = createAsyncThunk(
  "school/fetchStaffFeedback",
  async (data) => {
    const response = await get_staff_feedback_api(data);
    return response.data;
  }
);

export const fetchAcademicsNews = createAsyncThunk(
  "school/fetchAcademicsNews",
  async (data) => {
    const response = await get_academics_news_api(data);
    return response.data;
  }
);

export const fetchbirthdaylocation = createAsyncThunk(
  "school/fetchbirthdaylocation",
  async (data) => {
    const response = await get_birthday_location_api(data);
    return response.data;
  }
);

// export const fetchEventsStaff = createAsyncThunk(
//   "school/fetchEventsStaff",
//   async (data) => {
//     const response = await get_events_staff_api(data);
//     return response.data;
//   }
// );

export const fetchBirthdayFeedback = createAsyncThunk(
  "school/fetchBirthdayFeedback",
  async (data) => {
    const response = await get_birthday_feedback_api(data);
    return response.data;
  }
);
const schoolSlice = createSlice({
  name: "school",
  initialState: {
    schoolInfo: null,
    galleryFacilities: null,
    academicsNews: null,
    // eventsStaff: null,
    birthdayFeedback: null,
    loadingBirthdayFeedback: false,
    birthdaylocation: null,
    errorBirthdayFeedback: null,
    StaffFeedback: null,
    loading: false,
    loadingAcademicsNews: false,
    errorAcademicsNews: null,
    error: null,
  },
  reducers: {
    set_academics_news(state, action) {
      state.academicsNews = action.payload;
    },
    set_gallery_facility_up(state, action) {
      state.galleryFacilities = action.payload;
    },
    set_staff_parent(state, action) {
      state.StaffFeedback = action.payload;
    },
    set_birthday_location(state, action) {
      state.birthdaylocation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchoolInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchoolInfo.fulfilled, (state, action) => {
        state.schoolInfo = action.payload;
        state.loading = false;
      })
      .addCase(fetchSchoolInfo.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      // Handling fetchGalleryFacilities actions
      .addCase(fetchGalleryFacilities.pending, (state) => {
        state.loadingGalleryFacilities = true;
        state.errorGalleryFacilities = null;
      })
      .addCase(fetchGalleryFacilities.fulfilled, (state, action) => {
        state.galleryFacilities = action.payload;
        state.loadingGalleryFacilities = false;
      })
      .addCase(fetchGalleryFacilities.rejected, (state, action) => {
        state.errorGalleryFacilities = action.error.message;
        state.loadingGalleryFacilities = false;
      })
      // Handling fetchAcademicsNews actions
      .addCase(fetchAcademicsNews.pending, (state) => {
        state.loadingAcademicsNews = true;
        state.errorAcademicsNews = null;
      })
      .addCase(fetchAcademicsNews.fulfilled, (state, action) => {
        state.academicsNews = action.payload;
        state.loadingAcademicsNews = false;
      })
      .addCase(fetchAcademicsNews.rejected, (state, action) => {
        state.errorAcademicsNews = action.error.message;
        state.loadingAcademicsNews = false;
      })
      // Handling fetchEventsStaff actions
      // .addCase(fetchEventsStaff.pending, (state) => {
      //   state.loadingEventsStaff = true;
      //   state.errorEventsStaff = null;
      // })
      // .addCase(fetchEventsStaff.fulfilled, (state, action) => {
      //   state.eventsStaff = action.payload;
      //   state.loadingEventsStaff = false;
      // })
      // .addCase(fetchEventsStaff.rejected, (state, action) => {
      //   state.errorEventsStaff = action.error.message;
      //   state.loadingEventsStaff = false;
      // })
      .addCase(fetchBirthdayFeedback.pending, (state) => {
        state.loadingBirthdayFeedback = true;
        state.errorBirthdayFeedback = null;
      })
      .addCase(fetchBirthdayFeedback.fulfilled, (state, action) => {
        state.birthdayFeedback = action.payload;
        state.loadingBirthdayFeedback = false;
      })
      .addCase(fetchBirthdayFeedback.rejected, (state, action) => {
        state.errorBirthdayFeedback = action.error.message;
        state.loadingBirthdayFeedback = false;
      })
      //
      .addCase(fetchStaffFeedback.pending, (state) => {})
      .addCase(fetchStaffFeedback.fulfilled, (state, action) => {
        state.StaffFeedback = action.payload;
      })
      .addCase(fetchStaffFeedback.rejected, (state, action) => {})
      //
      .addCase(fetchbirthdaylocation.pending, (state) => {})
      .addCase(fetchbirthdaylocation.fulfilled, (state, action) => {
        state.birthdaylocation = action.payload;
      })
      .addCase(fetchbirthdaylocation.rejected, (state, action) => {});
  },
});
export const {
  set_academics_news,
  set_gallery_facility_up,
  set_staff_parent,
  set_birthday_location,
} = schoolSlice.actions;

export default schoolSlice.reducer;
