import { configureStore } from "@reduxjs/toolkit";
import schoolReducer from "./slices/schoolSlice";
import webConfigReducer from "./slices/webConfigSlice";

export const store = configureStore({
  reducer: {
    school: schoolReducer,
    webConfig: webConfigReducer,
  },
});
