import { apiPost } from "../utility/apiUtils";
// export const BASE_URL = "http://35.159.9.64:5500/v1/";
export const BASE_URL = "http://schoolmantra.in:5500/v1/";
// export const BASE_URL = "http://192.168.29.46:5500/v1/";

// export const BASE_URL = "http://192.168.29.237:5500/v1/";
export const FILE_SUFFIX = "files/Schools/";
export const BANNER_SUFFIX = "/Website/Banners/";
export const ACADEMICS_SUFFIX = "/Website/Academics/";
export const NEWS_SUFFIX = "/Website/News/";
export const FACILITIES_SUFFIX = "/Website/Facilities/";
export const STAFF_SUFFIX = "Staff/";
export const GALLERY_SUFFIX = "/Website/Events/";
export const STUDENT_SUFFIX = "Students/";
// export const db_name = "spsbslp1";

export function Student_image(db_name, pic) {
  return BASE_URL + `files/${db_name}/Students/${pic}`;
}

export const get_school_info_api = (data) => {
  return apiPost("school-info", data);
};

export const get_gallery_facilities_api = (data) => {
  return apiPost("gallery-facilities-info", data);
};
export const get_academics_news_api = (data) => {
  return apiPost("news-academic-info", data);
};
export const get_staff_feedback_api = (data) => {
  return apiPost("staff-feedback-info", data);
};

export const get_birthday_location_api = (data) => {
  return apiPost("birthday-location-info", data);
};

// export const get_events_staff_api = (data) => {
//   return apiPost("event-staff-info", data);
// };

export const get_school_activity_api = (data) => {
  return apiPost("get-school-activity", data);
};

// export const get_birthday_list = (data) => {
//   return apiPost("get-student-Bday-list", data);
// };
export const get_subscribe_api = (data) => {
  return apiPost("add-subscriber", data);
};

// export const get_parents_list = (data) => {
//   return apiPost("get-parent-feedBack", data);
// };

// export const get_locations_api = (data) => {
//   return apiPost("get-locations", data);
// };

export const get_school_details_api = (data) => {
  return apiPost("get-school-details", data);
};

export const get_web_configuration_api = (data) => {
  return apiPost("get-web-configuration", data);
};
export const get_enquiry_api = (data) => {
  return apiPost("insert-enquiry", data);
};

export const get_birthday_feedback_api = (data) => {
  return apiPost("student-feedback-info", data);
};
